import React from "react";
import "../styles/About.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const About = () => {
  const images = [
    {
      id: 1,
      src: require("../assets/picOne.jpeg"),
      alt: "Solar Product 1",
    },
    {
      id: 2,
      src: require("../assets/picTwo.jpeg"),
      alt: "Solar Product 2",
    },
    {
      id: 3,
      src: require("../assets/picThree.jpeg"),
      alt: "Solar Product 3",
    },
    {
      id: 4,
      src: require("../assets/picFour.jpeg"),
      alt: "Solar Product 4",
    },
    {
      id: 5,
      src: require("../assets/picFive.jpeg"),
      alt: "Solar Product 5",
    },
    {
      id: 6,
      src: require("../assets/picSix.jpeg"),
      alt: "Solar Product 6",
    },
    {
      id: 7,
      src: require("../assets/picSeven.jpeg"),
      alt: "Solar Product 7",
    },
    {
      id: 8,
      src: require("../assets/picEight.jpg"),
      alt: "Solar Product 8",
    },
    {
      id: 9,
      src: require("../assets/picNine.jpeg"),
      alt: "Solar Product 9",
    },
    {
      id: 10,
      src: require("../assets/picTen.jpeg"),
      alt: "Solar Product 10",
    },
    {
      id: 11,
      src: require("../assets/picEleven.jpeg"),
      alt: "Solar Product 11",
    },
    {
      id: 12,
      src: require("../assets/picTwelve.jpeg"),
      alt: "Solar Product 12",
    },
    {
      id: 13,
      src: require("../assets/picThirteen.jpeg"),
      alt: "Solar Product 13",
    },
    {
      id: 14,
      src: "https://m.media-amazon.com/images/I/71yZQ-A1EwL._AC_UF894,1000_QL80_.jpg",
      alt: "Solar Product 14",
    },
    {
      id: 15,
      src: "https://m.media-amazon.com/images/I/71yZQ-A1EwL._AC_UF894,1000_QL80_.jpg",
      alt: "Solar Product 15",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Auto-moving
    autoplaySpeed: 3000, // Speed of auto movement (in milliseconds)
    arrows: false, // Remove navigation arrows if you want
  };

  return (
    <>
      <section className="containerAbout">
        <div className="carousel-container">
          <Slider {...settings}>
            {images.map((image) => (
              <div key={image.id}>
                <img
                  src={image.src}
                  alt={image.alt}
                  className="carousel-image"
                />
              </div>
            ))}
          </Slider>
        </div>
        <div className="aboutusContent">
          <h1 className="aboutHeader">About Us</h1>
          <p className="paragraphAbout">
            We are an independent Solar Power Product dealer located steps from
            the centre of town and are proud to be the quality solar product
            dealer in term of sales and services with complete installation. We
            are distributor of three renowned solar companies UTL, USHA and
            Smarten Power Systems Pvt Ltd, with their large range of solar
            products. Please feel free to contact us on WhatsApp No. 9415164872
            and even come to visit our shop thank you.
          </p>
        </div>
      </section>
    </>
  );
};

export default About;
