import React from "react";
import "../styles/Contact.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import logo from "../assets/logo.png"; // Adjust the path to where your logo is stored

const Contact = () => {
  const phoneNumber = "+919415164872";
  return (
    <>
      <section className="containerContact">
        <h1 className="contactUsHeader">Contact Us</h1>
        <div className="contactContainer">
          <div className="contactDetails">
            <p className="paragraphHeaderOne">
              406, Uncha Tella G.T Road Sitapur, UP
            </p>
            <p className="paragraphBodyTwo">+91 9415164872</p>
            <p className="paragraphBodyTwo">+91 8299804437</p>
            <p className="paragraphTittleThree">harshsolar406@gmail.com</p>
          </div>
          <div className="mapContainer">
            <iframe
              title="Harsh Solar Power Solution Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1120.923822387168!2d80.6640722657786!3d27.574425604349848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399ed7a5500a4ee5%3A0x13098a5157f0f2ae!2sHarsh%20Solar%20Power%20Solution!5e0!3m2!1sen!2sin!4v1723356609758!5m2!1sen!2sin"
              // width="500"
              // height="300"
              // style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="bodyConatiner">
          <div className="logoContainer">
            <img
              src={logo}
              alt="Harsh Solar Power Solution Logo"
              className="logoImage"
            />
          </div>
          <p className="paragraphHeader">Harsh Solar Power Solution </p>
          <p className="paragraphBody">Copyright © 2024 All rights reserved.</p>
          <p className="paragraphTittle">
            Powered By: XDevelopers (+91 8059371148)
          </p>
          {/* <p className="paragraphTittle">+91 8059371148</p> */}
        </div>
      </section>
      {/* WhatsApp Button */}

      <a
        href={`https://wa.me/${phoneNumber}?text=Hello,%20I%20would%20like%20to%20know%20more%20about%20your%20solar%20products.`}
        target="_blank"
        rel="noopener noreferrer"
        className="whatsappButton"
      >
        <div className="whatsappIcon">
          <i className="fab fa-whatsapp"></i>
        </div>
        <div className="whatsappText">Chat with us</div>
      </a>
    </>
  );
};

export default Contact;
