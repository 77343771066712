import React from "react";
import "../styles/Home.css";

const Home = () => {
  return (
    <section className="containerHome">
       <div className="headerContainer">
        <h1 className="headerText">HARSH SOLAR POWER SOLUTION</h1>
      </div>
      <div className="bodyContainer">
        <h1 className="headerTitle">Welcome</h1>
        <p className="paragraphHome">
          Your reliable partner for sustainable energy solutions.
        </p>
      </div>
    </section>
  );
};

export default Home;
